import React, { useContext, useEffect, useState } from "react";
import { PageProps } from "gatsby";
import { LoadingContext } from "@context";
import { getAuth } from "firebase/auth";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { useAuth } from "@hooks";
import HeaderMobile from "@components/Structural/Bild/HeaderMobile";
import { PlanCard } from "@components/Services/PersonalizedPlans/PlanCard/PlanCard";
import _ from "lodash";
import { getSubscriptionPlans } from "@apollo";
import { Footer } from "@components/Structural/Landing/Footer";
import { ButtonOutlined } from "@components/Reusables";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Step2PlanCard } from "@components/Services/PersonalizedPlans/Step2PlanCard/Step2PlanCard";

const SaveByRokinPage: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });

  const classes = useStyles();
  const { hideLoader } = useContext(LoadingContext);
  const currentUser = getAuth().currentUser;
  const [isOpenMenu, setOpenMenu] = useState(false);
  const isMobile = useMediaQuery("(max-width: 1300px)");

  useEffect(() => {
    hideLoader("");
  }, [currentUser]);

  const { subscriptionPlans } = getSubscriptionPlans();
  const [Step, setStep] = useState(1);
  const [chosenPlan, setChosenPlan] = useState([]);

  const plansList = _.cloneDeep(subscriptionPlans);
  plansList.sort((a, b) => a.id - b.id);

  const GetPlans = () => {
    return (
      <>
        {plansList.map((item, index) => {
          return (
            <div key={index} className={classes.plancard}>
              <PlanCard
                setStep={setStep}
                data={item}
                setChosenPlan={setChosenPlan}
                isOpen={true}
                landingVersion
              />
            </div>
          );
        })}
      </>
    );
  };
  return (
    <>
      {" "}
      <section className={classes.background}>
        <HeaderMobile
          className={classes.menu}
          secondLanding={false}
          isOpenMenu={isOpenMenu}
        />
        <div
          className={classes.imgMenu}
          onClick={() => setOpenMenu(!isOpenMenu)}
        >
          {isOpenMenu ? (
            <img
              className="burger2"
              src="https://rokinapp-images.s3.amazonaws.com/Landing/redes/Navigation+_+close.svg"
            />
          ) : (
            <img
              className="burger"
              src={
                "https://storage.googleapis.com/rokinapp-images/icons/HamMenu_icon.svg"
              }
            />
          )}
          <span className={classes.title}>Servicios rokin</span>
        </div>
        <div className={classes.principalTitle}>
          Regístrate,{isMobile && <br />} elige un servicio,{" "}
          {isMobile && <br />}
          <span>¡y mejora tus finanzas!</span>
        </div>
        <div>
          <div className={classes.plansContainer}>{GetPlans()}</div>
        </div>
        <div className={classes.textBottom}>
          <span>¿Quieres hacer otras cosas con tus finanzas?</span>
          <ButtonOutlined text="Ver otros productos Rokin" />
        </div>
        {Step === 2 && (
          <div className={classes.position}>
            <div className={classes.container}>
              <div className={classes.stepAndCloseButtonStep2}>
                <div className={classes.stepAndbackButton}>
                  <div className={classes.pointer}></div>
                  <p className={classes.stepText}> </p>
                </div>
                <div className={classes.pointer}>
                  <CloseRoundedIcon
                    htmlColor="FFFFFF"
                    onClick={() => {
                      setStep(1);
                    }}
                  />
                </div>
              </div>
              <p className={classes.titleTextStep2}>Servicios Personalizados</p>
              <div className={classes.vectorStep2}></div>
              <p className={classes.descriptionTextStep2}>
                ¡Elegiste el Pack <strong>{chosenPlan.name}</strong>!. Revisa el
                detalle y confirma tu compra
              </p>
              <div className={classes.plansContainer}>
                <Step2PlanCard data={chosenPlan} setStep={setStep} />
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default SaveByRokinPage;

const useStyles = makeStyles((theme) => ({
  layoutOnBoarding: {
    background:
      "linear-gradient(151deg, rgb(10, 86, 105) 6%, rgb(0, 169, 194) 203%)",
    minHeight: "100vh",
    fontFamily: "Montserrat",
  },
  menu: {
    [theme.breakpoints.down(1240)]: {
      left: "0px!important",
      transition: "0.5s",
      right: "-40rem",
      top: 0,
    },
  },
  title: {
    textTransform: "uppercase",
  },
  titleTextStep2: {
    fontSize: "1.5rem",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down(1240)]: {
      fontSize: "1rem",
      margin: "0 2rem 0 2rem",
    },
  },
  vectorStep2: {
    width: "auto",
    height: "0.125rem",
    borderTop: "1px solid #FFFFFF",
    margin: "0 2rem 0 2rem",
  },
  container: {
    width: "42.5rem",
    display: "flex",
    margin: "0 auto",
    flexDirection: "column",
    background: "#084554",
    padding: "1rem 2rem 2.5rem 2rem",
    color: "#FFFFFF",
    gap: "1rem",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      height: "100%",
      padding: ({ Step }) => (Step ? "0" : "2.5rem 2rem 10rem 2rem"),
    },
    "& p": {},
  },
  position: {
    display: "flex",
    background: "rgba(0,0,0,0.3)",
    width: "100%",
    minHeight: "100vh",
    position: "fixed",
    left: 0,
    top: "0",
    zIndex: 99,
    alignItems: "center",
    justifyContent: "center",
    PointerEvent: "none",
    overflowY: "hidden",
    "& >div:hover": {
      PointerEvent: "auto",
    },
  },
  descriptionTextStep2: {
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    textAlign: "center",
    [theme.breakpoints.down(1240)]: {
      fontSize: "0.75rem",
      margin: "0 2rem 0 2rem",
    },
  },
  plancard: {
    width: "21rem",
    height: "24.9rem",
    background: "#0a5a64",
    padding: "1rem",
    borderRadius: 10,
    "& >div": {
      background: "none",
      padding: "0",
    },
  },
  plansContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "2.5rem",
    width: "70rem",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
    },
  },
  imgMenu: {
    display: "flex",
    fontSize: "1.5rem",
    alignItems: "center",
    color: "white",
    gap: "2rem",
    "& .burger2": {
      position: "absolute",
      top: "5rem",
      zIndex: 99999,
      right: "0.5rem",
      [theme.breakpoints.down(1240)]: { top: "1rem" },
    },
  },
  stepAndbackButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
    [theme.breakpoints.down(1240)]: {
      gap: "0.5rem",
    },
  },
  stepAndCloseButtonStep2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0rem 2rem 0 2rem",
  },
  background: {
    background: "#00323c",
    padding: "4.3rem 10.2rem",
    fontFamily: "Montserrat",
    [theme.breakpoints.down(1240)]: { padding: "4.3rem 1rem" },
  },
  principalTitle: {
    fontSize: "2.5rem",
    color: "white",
    fontFamily: "Montserrat",
    padding: "3rem 0",
    fontWeight: 300,
    [theme.breakpoints.down(1240)]: {
      fontSize: "1.5rem",
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  stepAndCloseButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  stepText: {
    fontSize: "0.875rem",
    fontFamily: "Nunito",
    [theme.breakpoints.down(1240)]: {
      fontSize: "0.625rem",
    },
  },
  pointer: {
    cursor: "pointer",
  },
  textBottom: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "25rem",
    fontWeight: 500,
    color: "white",
    margin: "8em auto 0",
    [theme.breakpoints.down(1240)]: {
      width: "13rem",
      fontWeight: "400",
      textAlign: "center",
      margin: "6em auto",
      fontSize: "0.85rem",
    },
    "& button": {
      border: "1px solid white",
      color: "white",
      width: "19rem",
      margin: "0 auto",
      [theme.breakpoints.down(1240)]: {
        width: "13rem",
        fontSize: "0.85rem",
      },
    },
  },
}));
